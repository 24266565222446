import React, { useEffect, useContext, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, json, useNavigate } from "react-router-dom";
import { AppSettings } from "../../config/app-settings.js";
import dataService from "../../_services/DataService.js";
import { Modal } from "../../components/modal/modal.js";
import logo from "../../assets/images/today-logo.png";
import { useNavigation } from "react-router-dom";
import Alert from "../../components/alert/alert.js";
import { TableTimer } from "./tableTimer.js";
import { HubConnectionBuilder } from "@microsoft/signalr";
import "./order-processor.scss";
import { environment } from "../../_services/environment.js";

const connection = new HubConnectionBuilder()
  .withUrl(environment.TableBaseURL + "/reservify")
  .build();
connection.id = "12345";
connection
  .start()
  .then(() => console.log("Connected to SignalR hub"))
  .catch((err) => console.error("Error connecting to hub:", err));

function OrderProcessor() {
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedArea, setSelectedArea] = useState("All");
  const [selectedTable, setSelectedTable] = useState();
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    const handleUpdate = (data) => {
      updateTableStatusState(data);
    };

    connection.on("updateTableStatusById", handleUpdate);

    return () => {
      connection.off("updateTableStatusById", handleUpdate);
    };
  }, []);

  const fetchTables = async () => {
    try {
      const response = await dataService.getTables();
      setTableData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTables();
  }, []);

  function toggleMobileSidebar(event, table) {
    event.preventDefault();
    setSelectedTable(table);
  }

  const navigate = useNavigate();
  function handleModalOk() {
    navigate(`/pos/orderplacement/${selectedTable.id}`, {
      state: { tableId: selectedTable.id },
    });
    setSelectedTable(null);
  }

  function handleModalClose() {
    console.log("close");
    setSelectedTable(null);
  }

  const getUniqueAreaNames = () => {
    if (!tableData) return [];
    const areaNames = tableData.map((table) => table.tableAreaName);
    return [...new Set(areaNames)];
  };

  const filterData = () => {
    let filtered = tableData;
    if (searchInput) {
      filtered = filtered.filter(
        (table) =>
          table.status?.toLowerCase().includes(searchInput.toLowerCase()) ||
          table.id?.toString().includes(searchInput)
      );
    }

    if (selectedArea !== "All") {
      filtered = filtered.filter(
        (table) => table.tableAreaName === selectedArea
      );
    }

    setFilteredData(filtered);
  };

  useEffect(() => {
    const userName = localStorage.getItem("userName");
    if (!userName) {
      navigate("/");
    }
    const selectedArea = localStorage.getItem("selectedArea");
    if (selectedArea) {
      setSelectedArea(selectedArea);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedArea", selectedArea);
    filterData();
  }, [searchInput, selectedArea, tableData]);

  const updateTableStatusState = async (obj) => {
    const data = JSON.parse(obj);
    setTableData((prev) => {
      return prev.map((item) => {
        if (item.id === data.TableId) {
          // setSelectedTable({ ...item, status: data.Status.toUpperCase() });
          return { ...item, status: data.Status.toUpperCase() };
        }
        return item;
      });
    });

    setFilteredData((prev) =>
      prev.map((item) =>
        item.id === data.TableId
          ? { ...item, status: data.Status.toUpperCase() }
          : item
      )
    );
    // fetchTables();
  };

  const updateTableStatus = async (obj) => {
    const response = await dataService.updateTableStatus(obj);
    // fetchTables();,res
  };

  const APIChangeStatus = (item, newStatus, children) => {
    updateTableStatus({ tableId: item.id, status: newStatus });
  };

  const handleStatusChange = (item, newStatus) => {
    APIChangeStatus(item, newStatus);
    setAlertMessage(`Status changed successfully`);
  };

  return (
    <>
      {alertMessage && (
        <Alert message={alertMessage} onClose={() => setAlertMessage("")} />
      )}
      <div
        className={"pos pos-vertical pos-with-header pos-with-sidebar "}
        id="pos"
      >
        <div className="pos-container">
          <div className="col-12 col-xl-3 col-lg-3 ms-auto d-flex table-refresh">
            <div className="span search_cont">
              <input
                type="text"
                className="form-control search"
                id="searchInput"
                placeholder="Enter text to search"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={fetchTables}
            >
              Refresh
            </button>
          </div>
          <div className="pos-header">
            <div className="logo">
              <Link to="/pos/orderplacement" href="pos_counter_checkout.html">
                <div className="logo-text">
                  <img src={logo} className="logo-img" />
                </div>
              </Link>
            </div>

            <div className="nav">
              <div className="nav-item">
                <Link
                  to="/"
                  onClick={() => localStorage.clear()}
                  className="nav-link"
                >
                  <i className="fa fa-sign-out-alt"></i> Logout
                </Link>
              </div>
            </div>
          </div>

          {selectedTable && (
            <Modal>
              <div className="modal-header">
                <h5 className="modal-title">{selectedTable.id}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleModalClose}
                ></button>
              </div>
              <div className="modal-body">
                <p>Select from the below status:</p>
                {selectedTable.status !== "ORDERED" && (
                  <div>
                    {selectedTable.status.toUpperCase() === "AVAILABLE" ? (
                      <button
                        type="button"
                        className="btn btn-default reserved-btn"
                        onClick={() =>
                          handleStatusChange(selectedTable, "RESERVED")
                        }
                      >
                        Reserved
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn available-btn"
                        onClick={() =>
                          handleStatusChange(selectedTable, "AVAILABLE")
                        }
                      >
                        Available
                      </button>
                    )}
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={handleModalClose}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-outline-theme"
                  onClick={handleModalOk}
                >
                  Book Order
                </button>
              </div>
            </Modal>
          )}
          <div className="pos-content pos-tables">
            <div className="pos-filter-btn">
              <button
                className={`nav-link ${searchInput === "" ? "active" : ""}`}
                onClick={() => setSearchInput("")}
              >
                All
              </button>
              <button
                className={`nav-link avail_btn ${
                  searchInput === "Available" ? "active" : ""
                }`}
                onClick={() => setSearchInput("Available")}
              >
                Available
              </button>
              <button
                className={`nav-link reserv_btn ${
                  searchInput === "Reserved" ? "active" : ""
                }`}
                onClick={() => setSearchInput("Reserved")}
              >
                Reserved
              </button>
              <button
                className={`nav-link ordered_btn ${
                  searchInput === "Ordered" ? "active" : ""
                }`}
                onClick={() => setSearchInput("Ordered")}
              >
                Ordered
              </button>
              <button
                className={`nav-link billed_btn ${
                  searchInput === "Billed" ? "active" : ""
                }`}
                onClick={() => setSearchInput("Billed")}
              >
                Billed
              </button>
            </div>

            <div className="pos">
              <div className="pos-container">
                <div className="pos-content h-100">
                  <div className="pos-content-container p-3 h-100">
                    <div className="row gx-3 pos-all-tables">
                      <div className="horix_tabs">
                        {getUniqueAreaNames().map((areaName, index) => (
                          <button
                            className={`nav-link ${
                              selectedArea === areaName ? "active" : ""
                            }`}
                            key={index}
                            onClick={() => setSelectedArea(areaName)}
                          >
                            {areaName}
                          </button>
                        ))}
                      </div>
                      {filteredData && filteredData.length > 0 ? (
                        filteredData.map((table, index) => (
                          <div
                            className="col-xl-2 col-lg-2 col-md-3 col-sm-4 pb-3"
                            key={index}
                          >
                            <div
                              className={
                                "pos-checkout-table" +
                                (selectedTable && table.id === selectedTable.id
                                  ? " selected"
                                  : "") +
                                (table.status !== "AVAILABLE"
                                  ? " available"
                                  : "")
                              }
                            >
                              <a
                                href="#/"
                                className="pos-checkout-table-container"
                                onClick={(event) =>
                                  toggleMobileSidebar(event, table)
                                }
                              >
                                <div className="pos-checkout-table-header">
                                  <div className="fw-semibold">Table</div>
                                  <div className="fw-bold fs-1">{table.id}</div>
                                </div>
                                <div className="pos-checkout-table-info small">
                                  <div className="row">
                                    <div className="col-6 d-flex justify-content-center align-items-center gap-1">
                                      <div className="w-20px">
                                        <i className="far fa-clock text-body text-opacity-50"></i>
                                      </div>
                                      <TableTimer table={table} />
                                    </div>
                                    <div className="col-6 d-flex justify-content-center">
                                      <div
                                        className={`col-6 d-flex justify-content-center status-tag ${
                                          table.status.toUpperCase() ===
                                          "AVAILABLE"
                                            ? "available"
                                            : table.status.toUpperCase() ===
                                              "ORDERED"
                                            ? "ordered"
                                            : table.status.toUpperCase() ===
                                              "BILLED"
                                            ? "billed"
                                            : "reserved"
                                        }`}
                                      >
                                        <div className={"w-60px"}>
                                          {table.status
                                            ?.charAt(0)
                                            .toUpperCase() +
                                            table.status
                                              ?.slice(1)
                                              .toLowerCase()}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="pos-checkout-table-order">
                                  {table.orders &&
                                    table.orders.map((order, orderIndex) => (
                                      <div
                                        className="d-flex align-items-center py-1"
                                        key={orderIndex}
                                      >
                                        <div className="flex-1">
                                          <div className="fw-semibold">
                                            {order.name}
                                            <div className="text-muted small">
                                              ${order.price}
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <div className="fs-10px">
                                            <i className="fa fa-times text-danger"></i>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </a>
                            </div>
                          </div>
                        ))
                      ) : !tableData.length > 0 ? (
                        <div className="col-12 d-flex gap-2 align-items-center justify-content-center loader-container">
                          <div class="loader"></div>
                          <div class="loader2"></div>
                        </div>
                      ) : (
                        <div className="alert alert-warning">
                          No tables found
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderProcessor;
