import React, { useEffect, useState } from "react";
import { Modal, Form, InputNumber, Button, message } from "antd";

export const InvoiceModal = ({
  isVisible,
  totalAmount,
  onClose,
  onPost,
  driverName,
}) => {
  const [form] = Form.useForm();
  const [receivedAmount, setReceivedAmount] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(totalAmount);

  useEffect(() => {
    if (isVisible) {
      setReceivedAmount(0);
      setRemainingAmount(totalAmount);
      form.resetFields();
    }
  }, [isVisible, totalAmount, form]);

  const handleReceivedAmountChange = (value) => {
    if (value > 0) {
      setReceivedAmount(value);
      setRemainingAmount(totalAmount - value); // This will go negative if receivedAmount > totalAmount
    }
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (receivedAmount <= 0) {
          message.error("Received amount can not be less then or equal to 0.");
          return;
        }
        onPost(remainingAmount, totalAmount);
        setReceivedAmount(0);
        onClose();
      })
      .catch((error) => {
        message.error("Error");
        console.error("Validation failed:", error);
      });
  };

  const closeModal = () => {
    setReceivedAmount(0);
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title={`Invoice Details (Delivery Person: ${driverName})`}
      open={isVisible}
      onCancel={closeModal}
      onOk={handleSubmit}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Total Amount">
          <InputNumber
            value={totalAmount.toLocaleString()}
            disabled
            style={{ width: "100%", color: "black" }}
          />
        </Form.Item>
        <Form.Item
          label="Received Amount"
          name="receivedAmount"
          rules={[
            {
              required: true,
              message: "Please enter the received amount",
            },
            {
              validator: (_, value) =>
                value > 0
                  ? Promise.resolve()
                  : Promise.reject(new Error(`Received amount must be > 0`)),
            },
          ]}
        >
          <InputNumber
            min={0}
            value={receivedAmount}
            onChange={handleReceivedAmountChange}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item label="Remaining Amount">
          <InputNumber
            value={remainingAmount.toLocaleString()}
            disabled
            style={{ width: "100%", color: "black" }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
