import React, { useEffect, useContext, useState } from "react";
import { AppSettings } from "../../config/app-settings";
import dataService from "../../_services/DataService";
import { openDB } from "idb";
import { Link, useParams } from "react-router-dom";
import { OrderCart } from "./orderCart";
import { useNavigate } from "react-router-dom";
import { SummaryModal } from "./SummaryModal";
import { AddToCartModal } from "./addToCartModal";
import { Button } from "antd";
import BackImg from "../../assets/images/back.svg";
import { Modal } from "bootstrap";
import { environment } from "../../_services/environment";
import { LogoutOutlined } from "@ant-design/icons";
import { useAuth } from "../../_services/AuthenticationService";
import ArticlesLists from "./ArticlesLists";
import CategoriesList from "./CategoriesList";
import { useStateContext } from "../../context/ContextProvider";
import SubCategoryModal from "./SubCategoryModal";

function OrderPlacement() {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [Articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [summary, setSummary] = useState({
    customer: "DineIn",
    noOfGuests: 0,
  });
  const [posMobileSidebarToggled, setPosMobileSidebarToggled] = useState(false);
  const [summaryModal, setSummaryModal] = useState(false);
  const [categoryType, setCategoryType] = useState();
  const [modal, setModal] = useState();
  const [modalData, setModalData] = useState({ order: {}, isUpdate: false });
  const [SalesItem, setSalesItem] = useState([]);
  const { logout } = useAuth();
  const { id } = useParams();
  const {
    showSubCategoryModal,
    setShowSubCategoryModal,
    subCategoryBreadcrumb,
    setSubCategoryBreadcrumb,
  } = useStateContext();

  const fetchCurrentOrders = async (tableId, setSalesItem) => {
    try {
      const CurrentOrders = await dataService.getOrder(tableId);
      console.log("=============>", CurrentOrders);
      if (CurrentOrders.data?.receipts) {
        const salesItems = [];
        const firstReceipt = CurrentOrders.data.receipts[0];

        if (
          CurrentOrders.data.receipts.length <= 0 ||
          !firstReceipt.customerData
        ) {
          setSummaryModal(true);
        }

        if (firstReceipt?.customerData) {
          setSummary((prev) => ({
            ...prev,
            customer:
              firstReceipt.customerData.customerId === "CL-0008"
                ? "HipHop"
                : "DineIn",
            noOfGuests: firstReceipt.customerData.noOfGuests || 0,
          }));
        }

        CurrentOrders.data.receipts.forEach((item) => {
          item.salesItems.forEach((sale) => {
            sale.isReadOnly = true;
            salesItems.push(sale);
          });
        });

        console.log("sales items", salesItems, CurrentOrders);

        setSalesItem(salesItems);
      } else {
        console.log("No receipts found");
      }
    } catch (error) {
      console.log("Error occurred", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchCurrentOrders(id, setSalesItem);
    }
  }, [id]);

  const dbPromise = openDB("article-images-db", 1, {
    upgrade(db) {
      db.createObjectStore("images", { keyPath: "uuid" });
    },
  });

  const loadCategories = async () => {
    try {
      const response = await dataService.getCategories();
      if (typeof response !== "undefined") {
        setCategories(response.data?.resultList);
      }
      console.log("-----", response);
    } catch (error) {
      console.log("error occured =>", error);
    }
  };

  useEffect(() => {
    loadCategories();
  }, []);

  const toggleMobileSidebar = (event) => {
    event.preventDefault();

    setPosMobileSidebarToggled(true);
  };

  const dismissMobileSidebar = (event) => {
    event.preventDefault();

    setPosMobileSidebarToggled(false);
  };

  const showArticles = async (event, category, newCategory) => {
    event.preventDefault();
    console.log(event, category);

    try {
      setCategoryType(category.name);

      const response = await dataService.getArticles(category.uuid);
      console.log(
        "Articles response ++++++++++++++++++++++++++++++++++++",
        response
      );

      if (response && typeof response !== "undefined") {
        const articlesWithImages = await Promise.all(
          response.data?.resultList.map(async (item) => {
            let image = await loadArticleImage(item.uuid);
            if (!image) {
              image = await dataService.getArticleImage(item.uuid);
              if (image && image.data?.data) {
                image = "data:image/png;base64," + image.data?.data;
                saveArticleImage(item.uuid, image);
              }
            }
            return {
              ...item,
              image,
            };
          })
        );

        const subcategories =
          category.nodes && category.nodes.length > 0
            ? category.nodes.map((node) => ({
                type: "subcategory",
                name: node.name,
                uuid: node.uuid,
                id: node.id,
                description: node.description,
                image: node.image,
                position: node.position,
                displayMode: node.displayMode,
                nodes: node.nodes,
              }))
            : [];

        setSubCategoryBreadcrumb((prev) => {
          const isDuplicate = prev.some((item) => item.uuid === category.uuid);

          if (isDuplicate) {
            return prev;
          }
          if (newCategory && prev.length > 0) return [];
          return [
            ...prev,
            {
              ...category,
            },
          ];
        });

        setArticles([...subcategories, ...articlesWithImages]);
        setFilteredArticles([...subcategories, ...articlesWithImages]);
      }
    } catch (error) {
      console.log("error occurred =>", error);
    }
  };

  const loadArticleImage = async (uuid) => {
    try {
      const db = await dbPromise;
      const imageEntry = await db
        .transaction("images")
        .objectStore("images")
        .get(uuid);
      return imageEntry ? imageEntry.image : null;
    } catch (error) {
      console.error("Failed to load article image from IndexedDB", error);
      return null;
    }
  };

  const saveArticleImage = async (uuid, image) => {
    try {
      const db = await dbPromise;
      await db
        .transaction("images", "readwrite")
        .objectStore("images")
        .put({ uuid, image });
    } catch (error) {
      console.error("Failed to save article image to IndexedDB", error);
    }
  };

  const showPosItemModal = (event, food) => {
    event.preventDefault();
    food["quantity"] = 1;
    food["comment"] = [];
    food["remarks"] = "";
    if (food) {
      setModalData({ order: food, isUpdate: false });

      modal.show();
    }
  };

  const getOrderTotal = () => {
    return SalesItem
      ? SalesItem.filter((item) => item.description !== "CCO Rounding").length
      : 0;
  };

  useEffect(() => {
    context.setAppHeaderNone(true);
    context.setAppSidebarNone(true);
    context.setAppContentFullHeight(true);
    context.setAppContentClass("p-0");

    setModal(new Modal(document.getElementById("modalPosItem")));

    return function cleanUp() {
      context.setAppSidebarNone(false);
      context.setAppContentFullHeight(false);
      context.setAppContentClass("");
    };
  }, []);

  const changeSalesItem = (newData) => {
    console.log("updating ", newData);
    setSalesItem(newData);
  };

  const filterArticles = (event) => {
    console.log("event ", event.target.value);
    const filteredArticles = Articles.filter((article) => {
      return (
        article.description
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        article.unitPrice.toString().includes(event.target.value.toLowerCase())
      );
    });
    setFilteredArticles(filteredArticles);
  };

  return (
    <>
      {localStorage.getItem("delivery") === "false" && summaryModal && (
        <SummaryModal
          setSummaryModal={setSummaryModal}
          summaryModal={summaryModal}
          summary={summary}
          setSummary={setSummary}
        />
      )}
      {showSubCategoryModal && filteredArticles.length > 0 && (
        <SubCategoryModal
          filteredArticles={filteredArticles}
          showPosItemModal={showPosItemModal}
          showArticles={showArticles}
          setCategoryType={setCategoryType}
        />
      )}
      <div className="h-100">
        <div
          className={
            "pos pos-with-menu pos-with-sidebar " +
            (posMobileSidebarToggled ? "pos-mobile-sidebar-toggled" : "")
          }
          id="pos"
        >
          <OrderCart
            SalesItem={SalesItem}
            changeSalesItem={changeSalesItem}
            dismissMobileSidebar={dismissMobileSidebar}
            summary={summary}
            setSummary={setSummary}
            setModalData={setModalData}
            modal={modal}
          />
          <div className="pos-container PosContainer">
            <div className="pos-menu">
              <div class="heading_cont">
                {localStorage.getItem("delivery") === "false" && (
                  <span
                    class="back"
                    onClick={() => {
                      setSubCategoryBreadcrumb([]);
                      navigate("/pos/main");
                    }}
                  >
                    <img src={BackImg} />
                  </span>
                )}
                <h4>Choose Category</h4>

                <div class=" col-xl-3 col-lg-3 col-md-4 ms-auto mb-3 ">
                  <input
                    type="text"
                    class="search-input"
                    id="searchInput"
                    placeholder="Enter text to search"
                    onChange={(event) => filterArticles(event)}
                  />
                </div>
                <Button
                  type="primary"
                  icon={<LogoutOutlined />}
                  danger
                  onClick={() => {
                    localStorage.clear();
                    navigate("/");
                  }}
                  style={{
                    borderRadius: "5px",
                    padding: "0 20px",
                    height: "40px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    marginLeft: 6,
                  }}
                >
                  Logout
                </Button>
              </div>
              {categories.length > 0 && (
                <CategoriesList
                  categories={categories}
                  showArticles={showArticles}
                  categoryType={categoryType}
                />
              )}
            </div>

            <ArticlesLists
              filteredArticles={filteredArticles}
              showPosItemModal={showPosItemModal}
              showArticles={showArticles}
            />
          </div>
        </div>

        <a
          href="#/"
          className="pos-mobile-sidebar-toggler"
          onClick={toggleMobileSidebar}
        >
          <i className="fa fa-shopping-bag"></i>
          <span className="badge">{getOrderTotal()}</span>
        </a>

        {modalData && modalData.order && (
          <AddToCartModal
            modalData={modalData}
            setSalesItem={setSalesItem}
            SalesItem={SalesItem}
            setModalData={setModalData}
            modal={modal}
          />
        )}
      </div>
    </>
  );
}

export default OrderPlacement;
