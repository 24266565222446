import React from "react";
import { Modal, Card, Button, Breadcrumb, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import ArticlesLists from "./ArticlesLists";
import { useStateContext } from "../../context/ContextProvider";

const { Title } = Typography;

const SubCategoryModal = ({
  filteredArticles,
  showPosItemModal,
  showArticles,
  setCategoryType,
}) => {
  const {
    showSubCategoryModal,
    setShowSubCategoryModal,
    subCategoryBreadcrumb,
    setSubCategoryBreadcrumb,
  } = useStateContext();
  console.log("subCategoryBreadcrumb", subCategoryBreadcrumb);

  const handleBack = (e) => {
    const updatedBreadcrumb = subCategoryBreadcrumb.slice(0, -1);
    setSubCategoryBreadcrumb(updatedBreadcrumb);

    if (updatedBreadcrumb.length > 0) {
      const previousCategory = updatedBreadcrumb[updatedBreadcrumb.length - 1];
      showArticles(e, previousCategory);
    } else {
      setShowSubCategoryModal(false);
    }
  };

  const customHeader = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Button
        icon={<LeftOutlined />}
        onClick={(e) => handleBack(e)}
        style={{ marginRight: 10 }}
        type="dashed"
      />
      <Title level={4} style={{ margin: 0 }}>
        Subcategories
      </Title>
    </div>
  );

  return (
    <Modal
      title={customHeader}
      open={showSubCategoryModal}
      onCancel={(e) => {
        setCategoryType(subCategoryBreadcrumb[0].name || "");
        setShowSubCategoryModal(false);
        showArticles(e, subCategoryBreadcrumb[0] || "");
        setSubCategoryBreadcrumb([]);
      }}
      footer={[
        <Button
          key="close"
          onClick={(e) => {
            setCategoryType(subCategoryBreadcrumb[0].name || "");
            setShowSubCategoryModal(false);
            showArticles(e, subCategoryBreadcrumb[0] || "");
            setSubCategoryBreadcrumb([]);
          }}
        >
          Close
        </Button>,
      ]}
      width={1200}
      style={{ top: 20 }}
    >
      <div className="modal-content">
        <Breadcrumb style={{ marginBottom: "16px" }}>
          {subCategoryBreadcrumb.map((item) => (
            <Breadcrumb.Item key={item.uuid}>{item.name}</Breadcrumb.Item>
          ))}
        </Breadcrumb>

        <div className="pos">
          <div className="pos-container PosContainer" style={{ width: "100%" }}>
            <ArticlesLists
              filteredArticles={filteredArticles}
              showPosItemModal={showPosItemModal}
              showArticles={showArticles}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SubCategoryModal;
