import React from "react";
import { Form, Row, Col, Input, Button, Divider, Modal, message } from "antd";
import dataService from "../../_services/DataService";

const AddCustomerModal = ({ visible, onClose, setSearchTerm }) => {
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          const response = await dataService.addCustomer(values)
          form.resetFields();
          message.success("Customer added successfully!");
          setSearchTerm(values.phoneNumber)
          onClose();


        } catch (error) {
          console.error("Error adding customer:", error);
          message.error("Failed to add customer.");
        }

      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  return (
    <Modal
      title="Add New Customer"
      style={{
        top: 20,
      }}
      visible={visible}
      onCancel={onClose}
      onOk={handleSubmit}
      width={900}
    >
      <Form form={form} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[{ required: true, message: "Please enter first name" }]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[{ required: true, message: "Please enter last name" }]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[
                { required: true, message: "Please enter phone number" },
                {
                  pattern: /^[0-9]{11}$/,
                  message: "Please enter a valid phone number",
                },
              ]}
            >
              <Input placeholder="Phone Number" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Default Address"
              name="defaultAddress"
              rules={[
                { required: true, message: "Please enter default address" },
              ]}
            >
              <Input placeholder="Default Address" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Form.Item
              label="Remarks"
              name="udfStringXL1"
              rules={[{ required: true, message: "Please enter remarks" }]}
            >
              <Input.TextArea placeholder="Remarks" rows={3} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddCustomerModal;
