import React from "react";
import { environment } from "../../_services/environment";
export const AddToCartModal = ({
  modalData,
  setSalesItem,
  SalesItem,
  setModalData,
  modal,
}) => {
  //   const [modal, setModal] = useState();
  const CommentsEqual = (cmt1, cmt2) => {
    if (!cmt1 && !cmt2) return true;
    if (!cmt1 || !cmt2 || cmt1.length !== cmt2.length) return false;
    const sortedcmt1 = [...cmt1].sort();
    const sortedcmt2 = [...cmt2].sort();
    for (let i = 0; i < sortedcmt1.length; i++) {
      if (sortedcmt1[i] !== sortedcmt2[i]) return false;
    }
    return true;
  };

  const UpdateSaleItem = (event, item) => {
    event.preventDefault();

    setSalesItem((prev) =>
      prev.map((saleItem, index) =>
        saleItem.uuid === item.order.uuid && index === item.index
          ? { ...saleItem, ...item.order }
          : saleItem
      )
    );
    modal.hide();
  };

  var addToCart = (event) => {
    event.preventDefault();

    console.log("model data is ", modalData);
    console.log("sales items are ", SalesItem);

    setSalesItem((prev) => {
      if (!Array.isArray(prev)) {
        return [{ ...modalData.order }];
      }

      const existingItemIndex = prev.findIndex(
        (item) =>
          item.uuid === modalData.order.uuid &&
          CommentsEqual(item.comment, modalData.order.comment) &&
          (item.remarks
            ? item.remarks === modalData.order.remarks
            : !modalData.order.remarks)
      );

      console.log("existing is ", existingItemIndex);
      if (existingItemIndex !== -1) {
        return prev.map((item, index) =>
          index === existingItemIndex
            ? { ...item, quantity: item.quantity + modalData.order.quantity }
            : item
        );
      } else {
        return [...prev, { ...modalData.order }];
      }
    });

    modalClose();
  };

  var handleAddonChange = (event) => {
    var elms = [].slice.call(document.querySelectorAll('input[name="addon"]'));
    var targetValue = [];

    elms.map(function (elm) {
      if (elm.checked) {
        targetValue.push(elm.value);
      }
      return true;
    });
    console.log("comments are ", targetValue);

    setModalData((prev) => ({
      ...prev,
      order: {
        ...prev.order,
        comment: targetValue,
      },
    }));
  };

  var addModalQty = (event, data) => {
    event.preventDefault();

    var newQty = parseFloat(data.quantity) + 1;
    data.quantity = newQty;

    setModalData((prev) => ({
      ...prev,
      order: {
        ...prev.order,
        quantity: data.quantity,
      },
    }));
  };

  const updateQuantity = (event, data) => {
    event.preventDefault();
    const value = event.target.value;

    if (value === "" || /^\d*\.?\d*$/.test(value)) {
      setModalData((prev) => ({
        ...prev,
        order: {
          ...prev.order,
          quantity: getNumericValue(value),
        },
      }));
    }
  };

  const getNumericValue = (value) => {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  var deductModalQty = (event, data) => {
    event.preventDefault();
    console.log(data);
    var newQty = parseFloat(data.quantity) - 1;

    if (newQty < 1) {
      newQty = 1;
    }
    data.quantity = newQty;
    setModalData((prev) => ({
      ...prev,
      order: {
        ...prev.order,
        quantity: data.quantity,
      },
    }));
  };

  const modalClose = () => {
    setModalData({ order: {}, isUpdate: false });
    modal.hide();
    const elms = document.querySelectorAll('input[name="addon"]');
    elms.forEach((elm) => {
      elm.checked = false;
    });
  };

  return (
    <div className="modal modal-pos fade" id="modalPosItem">
      <div className="modal-dialog modal-lg">
        <div className="modal-content border-0">
          {modalData && modalData.order && (
            <div>
              <div className="p-0">
                <button
                  onClick={() => modalClose()}
                  className="btn-close position-absolute top-0 end-0 m-4"
                >
                  &nbsp;
                </button>
                <div className="modal-pos-product">
                  <div className="modal-pos-product-img">
                    <div
                      className="img"
                      style={{
                        backgroundImage: "url(" + modalData.order.image + ")",
                      }}
                    ></div>
                  </div>
                  <div className="modal-pos-product-info">
                    <div className="fs-4 fw-semibold">
                      {modalData.order.description}
                    </div>
                    <div className="text-body text-opacity-50 mb-2">
                      {modalData.order.shortText}
                    </div>
                    <div className="fs-3 fw-bold mb-3">
                      IQD{" "}
                      {(
                        modalData.order?.unitPrice / environment.TaxRateDivision
                      ).toLocaleString()}
                    </div>
                    <div className="d-flex mb-3">
                      <button
                        className="btn btn-secondary"
                        onClick={(event) =>
                          deductModalQty(event, modalData.order)
                        }
                      >
                        <i className="fa fa-minus"></i>
                      </button>
                      <input
                        type="number"
                        value={modalData.order?.quantity || "0"}
                        className="form-control w-70px fw-bold mx-2 text-center"
                        onChange={(event) =>
                          updateQuantity(event, modalData.order)
                        }
                        style={{ MozAppearance: "textfield" }}
                      />
                      <button
                        className="btn btn-secondary"
                        onClick={(event) => addModalQty(event, modalData.order)}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </div>

                    {modalData && modalData.order.textModules && (
                      <>
                        <hr className="mx-n4" />
                        <div className="mb-2">
                          <div className="fw-bold">Comments:</div>
                          <div className="option-list">
                            {modalData.order.textModules?.map((item, index) => (
                              <div className="option" key={item.uuid}>
                                <input
                                  type="checkbox"
                                  name="addon"
                                  className="option-input"
                                  onChange={(event) => handleAddonChange(event)}
                                  value={item.text}
                                  id={"addon" + index}
                                  checked={modalData.order.comment.includes(
                                    item.text
                                  )}
                                />
                                <label
                                  className="option-label"
                                  htmlFor={"addon" + index}
                                >
                                  <span className="option-text">
                                    {item.text}
                                  </span>
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}

                    <hr className="mx-n4" />
                    <p>Remarks:</p>
                    <textarea
                      class="form-control"
                      rows="3"
                      cols="3"
                      value={modalData.order.remarks}
                      onChange={(e) => {
                        e.preventDefault();
                        setModalData((prev) => ({
                          ...prev,
                          order: {
                            ...prev.order,
                            remarks: e.target.value,
                          },
                        }));
                      }}
                    ></textarea>

                    <div className="row">
                      <div className="col-4">
                        <button
                          className="btn btn-default fw-semibold mb-0 d-block py-3 w-100"
                          onClick={() => modalClose()}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="col-8">
                        <button
                          className="btn btn-theme fw-semibold d-flex justify-content-center align-items-center py-3 m-0 w-100"
                          onClick={(event) => {
                            modalData.isUpdate
                              ? UpdateSaleItem(event, modalData)
                              : addToCart(event);
                          }}
                        >
                          {modalData.isUpdate ? "Update" : "Add to cart"}
                          <i className="bi bi-plus fa-2x ms-2 my-n3"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
