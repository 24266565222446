import { createContext, useContext, useState } from "react";

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showSubCategoryModal, setShowSubCategoryModal] = useState(false);
  const [subCategoryBreadcrumb, setSubCategoryBreadcrumb] = useState([]);
  const [SubCategoryArticles, setSubCategoryArticles] = useState([]);

  return (
    <StateContext.Provider
      value={{
        isLoading,
        setIsLoading,
        showSubCategoryModal,
        setShowSubCategoryModal,
        subCategoryBreadcrumb,
        setSubCategoryBreadcrumb,
        SubCategoryArticles,
        setSubCategoryArticles,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
