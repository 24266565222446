import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Collapse, Button, Space, Spin, Empty, Table, message } from "antd";
import logo from "../../assets/images/today-logo.png";
import dataService from "../../_services/DataService";
import "./Delivery.scss";
import Loading from "../../components/loader/Loading";
import { useNavigate } from "react-router-dom";
import { InvoiceModal } from "./InvoiceModal";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { DateTime } from "../../utility/date-time";

const { Panel } = Collapse;

const PendingDeliveries = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [totalPrices, setTotalPrices] = useState({});
  const [selectedRows, setSelectedRows] = useState({});
  const [activeDeliveryId, setActiveDeliveryId] = useState(null);
  const [activeDeliveryPersonName, setActiveDeliveryPersonName] =
    useState(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await dataService.GetPendingDeliveries();

      setData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  };

  const navigate = useNavigate();

  const handlePostInvoice = async (remainingAmount, totalAmount) => {
    try {
      const invoices = selectedRows[activeDeliveryId] || [];

      const postData = {
        invoiceNo: invoices.map((item) => item.invoiceNo),
        deliveryPersonID: invoices[0]?.deliveryPersonID || null,
        totalAmount: totalAmount,
        remainingAmount,
      };

      setLoading(true);
      const response = await dataService.PostDelivery(postData);
      console.log("response from post delivery", response);

      setLoading(false);
      const { id } = response.data;
      if (!id) return;
      navigate(`/pos/delivery/recepit/${id}`);
    } catch (error) {
      setLoading(false);
      alert("Error posting the invoice");
      console.error(error);
    }
  };

  const groupedData = data.reduce((acc, item) => {
    const { deliveryPersonID } = item;
    if (!acc[deliveryPersonID]) {
      acc[deliveryPersonID] = [];
    }
    acc[deliveryPersonID].push(item);
    return acc;
  }, {});

  const handleRowSelectionChange = (
    selectedRowKeys,
    selectedRowsData,
    deliveryPersonID
  ) => {
    setSelectedRows((prev) => ({
      ...prev,
      [deliveryPersonID]: selectedRowsData,
    }));

    const total = selectedRowsData.reduce(
      (sum, record) => sum + record.totalAmount,
      0
    );
    setTotalPrices((prev) => ({
      ...prev,
      [deliveryPersonID]: total,
    }));
  };

  const handlePanelChange = (key) => {
    // setActivePanel(key);
    // setSelectedRows({});
    // setTotalPrices({});
  };

  const columns = [
    { title: "Invoice No", dataIndex: "invoiceNo", key: "invoiceNo" },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      render: (text) => {
        try {
          const formattedDate = DateTime.parse(text).toString();
          return formattedDate ? formattedDate : "Invalid Date";
        } catch (error) {
          return "Invalid Date";
        }
      },
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text) => text.toLocaleString(),
    },
  ];

  const showInvoiceDetailModal = (e, deliveryPersonID) => {
    e.stopPropagation();
    const hasSelectedRows = selectedRows[deliveryPersonID];

    if (!hasSelectedRows || !hasSelectedRows.length > 0) {
      message.error(
        `One of the Invoice from delivery id ${deliveryPersonID} must be selected`
      );
      return;
    }

    setActiveDeliveryId(deliveryPersonID);
    setActiveDeliveryPersonName(hasSelectedRows[0]?.deliveryPersonName);
    setShowModal(true);
  };

  return (
    <>
      <InvoiceModal
        isVisible={showModal}
        totalAmount={totalPrices[activeDeliveryId] ?? 0}
        onClose={() => setShowModal(false)}
        onPost={handlePostInvoice}
        driverName={activeDeliveryPersonName}
      />
      <div className="pos pos-with-header">
        <div className="pos-container">
          <div className="pos-header">
            <div className="logo">
              <Link to="/pos/orderplacement">
                <img src={logo} className="logo-img" alt="logo" />
              </Link>
            </div>
            <div className="nav">
              <div className="nav-item">
                <Link
                  to="/"
                  onClick={() => localStorage.clear()}
                  className="nav-link"
                >
                  <i className="fa fa-sign-out-alt"></i> Logout
                </Link>
              </div>
            </div>
          </div>

          <div className="pos-content pos-delivery">
            <Space
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                marginBottom: 12,
              }}
            >
              <Button
                icon={<ArrowLeftOutlined />}
                onClick={() => window.history.back()}
              >
                Back
              </Button>
              <Button
                type="primary"
                onClick={() => fetchData()}
                loading={loading}
              >
                Fetch Deliveries
              </Button>
            </Space>
            <Loading isLoading={loading}>
              {error ? (
                <Empty description="Error fetching data" />
              ) : Object.keys(groupedData).length === 0 ? (
                <Empty description="No Record" />
              ) : (
                <Collapse accordion onChange={handlePanelChange}>
                  {Object.keys(groupedData).map((deliveryPersonID) => {
                    return (
                      <Panel
                        key={deliveryPersonID}
                        header={
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <span style={{ marginRight: 68 }}>
                                {`${groupedData[deliveryPersonID][0].deliveryPersonName} (ID: ${deliveryPersonID})`}
                              </span>
                              <span>
                                Total:{" "}
                                {(
                                  totalPrices[deliveryPersonID] ?? 0
                                ).toLocaleString()}
                              </span>
                            </div>
                            <div>
                              <Button
                                type="dashed"
                                onClick={(e) =>
                                  showInvoiceDetailModal(e, deliveryPersonID)
                                }
                              >
                                Post Invoice
                              </Button>
                            </div>
                          </div>
                        }
                      >
                        <Table
                          columns={columns}
                          dataSource={groupedData[deliveryPersonID]}
                          pagination={false}
                          rowKey="invoiceNo"
                          rowSelection={{
                            type: "checkbox",
                            onChange: (selectedRowKeys, selectedRowsData) =>
                              handleRowSelectionChange(
                                selectedRowKeys,
                                selectedRowsData,
                                deliveryPersonID
                              ),
                          }}
                        />
                      </Panel>
                    );
                  })}
                </Collapse>
              )}
            </Loading>
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingDeliveries;
