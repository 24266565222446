import React from "react";
import { Card, Breadcrumb, Button } from "antd";
import { environment } from "../../_services/environment";
import { useStateContext } from "../../context/ContextProvider";
import { LeftOutlined } from "@ant-design/icons";

const { Meta } = Card;

const ArticlesLists = ({
  filteredArticles,
  showPosItemModal,
  showArticles,
}) => {
  const {
    showSubCategoryModal,
    setShowSubCategoryModal,
    subCategoryBreadcrumb,
    setSubCategoryBreadcrumb,
  } = useStateContext();

  const handleBack = (e) => {
    const updatedBreadcrumb = subCategoryBreadcrumb.slice(0, -1);
    setSubCategoryBreadcrumb(updatedBreadcrumb);

    if (updatedBreadcrumb.length > 0) {
      const previousCategory = updatedBreadcrumb[updatedBreadcrumb.length - 1];
      showArticles(e, previousCategory);
    }
  };

  return (
    <>
      <div className="pos-content PosContent">
        <div className="pos-content-container">
          <div className="row gx-4">
            {subCategoryBreadcrumb.length > 1 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 16,
                }}
              >
                <Button
                  icon={<LeftOutlined />}
                  onClick={(e) => handleBack(e)}
                  disabled={subCategoryBreadcrumb.length <= 1}
                  style={{ marginRight: 10, width: 25, height: 25 }}
                  type="dashed"
                />
                <Breadcrumb>
                  {subCategoryBreadcrumb.map((item) => (
                    <Breadcrumb.Item key={item.uuid}>
                      {item.name}
                    </Breadcrumb.Item>
                  ))}
                </Breadcrumb>
              </div>
            )}
            {filteredArticles && filteredArticles.length > 0 ? (
              filteredArticles.map((item, index) => (
                <div
                  className={
                    "col-xxl-2 col-xl-3 col-lg-4 col-md-3 col-sm-4 pb-2"
                  }
                  key={item.uuid}
                >
                  {item.type && item.type === "subcategory" ? (
                    <Card
                      title={item.name}
                      bordered={false}
                      className={"pos-product"}
                      style={{
                        // margin: "auto",
                        // display: "block",
                        // textAlign: "center",
                        // backgroundColor: "#f7f5f3",
                        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.3)",
                        // padding: "px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        // setShowSubCategoryModal(true);
                        showArticles(e, item);
                      }}
                    >
                      <Meta description={item.description || "Subcategory"} />
                    </Card>
                  ) : (
                    <a
                      href="#/"
                      className={"pos-product"}
                      onClick={(event) => showPosItemModal(event, item)}
                    >
                      <div className="foodImageContainer">
                        <img className="img" src={`${item.image}`} />
                      </div>
                      <div className="info">
                        <div className="title">{item.description}</div>
                        <div className="price">
                          IQD{" "}
                          {(
                            item.unitPrice / environment.TaxRateDivision
                          ).toLocaleString()}
                        </div>
                      </div>
                    </a>
                  )}
                </div>
              ))
            ) : (
              <div className="col-12">No records found</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticlesLists;
