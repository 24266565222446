import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useParams } from "react-router-dom";
import Alert from "../../components/alert/alert";
import dataService from "../../_services/DataService";
import { useNavigate } from "react-router-dom";
import { RemarksModal } from "./remarksModal";
import { environment } from "../../_services/environment.js";
import SearchCustomer from "../orderDelivery/SearchCustomer.js";
import { AutoComplete, Input, Spin, message, Button, Divider } from 'antd';
import { SearchOutlined, EditOutlined } from '@ant-design/icons';
import CustomerDetail from "../orderDelivery/CustomerDetail.jsx";

export const OrderCart = ({
  SalesItem,
  changeSalesItem,
  dismissMobileSidebar,
  summary,
  setSummary,
  setModalData,
  modal,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState({
    show: false,
    message: "Please add Guest(s).",
    class: "",
  });
  const [CcoRounding, setCcoRounding] = useState();
  const [showReamarks, setShowRemarks] = useState({ show: false, data: [] });
  const [Loading, setLoading] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  // const [customerDetails, setCustomerDetails] = useState({ name: "", address: [], phoneNumber: 0, defaultAddress: '' })
  const [details, setDetails] = useState({})
  const [searchTerm, setSearchTerm] = useState(details.firstName || '');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([]);
  const [customerData, setCustomerData] = useState([])
  const [showDetailsModal, setShowDetailsModal] = useState(false);



  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const fetchCustomerData = async (query) => {
    setFetching(true);
    try {
      const response = await dataService.fetchCustomerData(query);

      return response.data;
    } catch (error) {
      message.error("Failed to fetch customer data");
      return [];
    } finally {
      setFetching(false);
    }
  };


  useEffect(() => {
    if (debouncedSearchTerm) {
      fetchCustomerData(debouncedSearchTerm)
        .then((data) => {
          const formattedOptions = data.map((customer, index) => ({
            value: customer.id,
            label: (
              <> <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                  <div>{customer.firstName}</div>
                  <div>{customer.phoneNumber}</div>
                  <div>{customer.defaultAddress}</div>
                </div>
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDetails(customer)
                    setShowDetailsModal(true)
                  }}
                />
              </div>
                {index < data.length - 1 && <Divider style={{ margin: '8px 0' }} />}</>
            ),
          }));
          setCustomerData(data)
          setOptions(formattedOptions);
        })
        .catch((error) => {
          console.error("Error fetching customer data:", error);
        });
    } else {
      setOptions([]);
    }
  }, [debouncedSearchTerm]);



  const handleCustomerSelect = (value) => {
    if (value) {
      const customer = customerData.find(item => item.id === value)
      if (customer) {
        setDetails(customer);
        setSearchTerm(customer.firstName || '');
      }
    }
  };


  const changeDetails = (customer) => {
    setDetails(customer)
    setSearchTerm(customer.firstName ?? "")
  }

  const chaageDefaultAddress = (key, value) => {
    setDetails((prev) => {
      const { address, defaultAddress } = prev;

      const newDefaultIndex = address.findIndex((address) => address === value);

      const updatedAddresses = address.map((address, index) => {
        if (address === defaultAddress) {
          return value;
        }
        if (index === newDefaultIndex) {
          return defaultAddress;
        }
        return address;
      });


      return {
        ...prev,
        address: updatedAddresses,
        defaultAddress: value,
      };
    });

  }


  const addQty = (event, order, orderIndex) => {
    event.preventDefault();

    if (SalesItem) {
      const newData = SalesItem.map((item, index) =>
        item.uuid === order.uuid && index === orderIndex
          ? { ...item, quantity: (parseFloat(item.quantity) + 1).toFixed(1) }
          : item
      );
      changeSalesItem(newData);
    }
  };

  const handleKeyDown = (event, order, index) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.key === "ArrowUp") {
      addQty(event, order, index);
    } else if (event.key === "ArrowDown") {
      deductQty(event, order, index);
    }
  };

  const deductQty = (event, order, orderIndex) => {
    event.preventDefault();
    if (SalesItem) {
      const newData = SalesItem.map((item, index) =>
        item.uuid === order.uuid && index === orderIndex
          ? {
            ...item,
            quantity: Math.max((parseFloat(item.quantity) - 1).toFixed(1), 1),
          }
          : item
      );

      changeSalesItem(newData);
    }
  };

  useEffect(() => {
    let CcoRounding;
    if (SalesItem) {
      CcoRounding = SalesItem.find(
        (item) => item.description === "CCO Rounding"
      );
    }
    if (CcoRounding) setCcoRounding(CcoRounding);
  }, [SalesItem]);

  const removeOrder = (event, uuid, comment) => {
    event.preventDefault();

    if (SalesItem) {
      const newData = SalesItem.filter((item) => {
        return item.uuid !== uuid || item.comment !== comment;
      });

      changeSalesItem(newData);
    }
  };

  const getSubTotalPrice = () => {
    let subTotal = 0;

    if (SalesItem) {
      for (let i = 0; i < SalesItem.length; i++) {
        if (SalesItem[i].description !== "CCO Rounding") {
          const price = parseFloat(
            SalesItem[i].unitGrossAmount || SalesItem[i].unitPrice
          );
          const quantity = parseInt(SalesItem[i].quantity);

          if (!isNaN(price) && !isNaN(quantity)) {
            subTotal += price * quantity;
          }
        }
      }
    }
    return parseInt(subTotal);
  };

  const GetCcoRounding = () => {
    let sum = 0;

    if (SalesItem) {
      SalesItem.forEach((item) => {
        if (item.description === "CCO Rounding") {
          sum += item.paymentGrossAmount;
        }
      });
    }

    return sum;
  };

  const getTaxesPrice = () => {
    return parseInt(getSubTotalPrice() * environment.TaxRate);
  };

  const getTotalPrice = () => {
    let total = 0;
    let roundingAmount = 0;


    if (SalesItem) {
      for (let i = 0; i < SalesItem.length; i++) {
        const item = SalesItem[i];
        const unitAmount = parseFloat(item.unitGrossAmount || item.unitPrice);
        const quantity = parseInt(item.quantity);

        if (!isNaN(unitAmount) && !isNaN(quantity)) {
          if (item.description === "CCO Rounding") {
            roundingAmount += parseFloat(item.paymentGrossAmount);
          } else {
            total += unitAmount * quantity;
          }
        }
      }
      total += roundingAmount;
    }

    return parseInt(total);
  };

  const handleSummaryChange = (event) => {
    const { name, value } = event.target;
    setSummary((prevSummary) => ({
      ...prevSummary,
      [name]: name === "guests" ? +value : value,
    }));
  };

  const checkout = async () => {
    setLoading(true);

    const isDelivery = localStorage.getItem("delivery") === "false" ? false : true


    if (!isDelivery && summary.noOfGuests <= 0) {
      setLoading(false);
      setError((prev) => ({
        ...prev,
        show: true,
        class: "error",
      }));
      return;
    }

    var items = [];
    // eslint-disable-next-line array-callback-return
    SalesItem.map((item, index) => {
      item.comment?.push(item.remarks);
      item.id &&
        items.push({
          id: item.id,
          quantity: item.quantity,
          comment: item.comment.join("\n"),
        });
    });
    if (items.length <= 0) {
      setLoading(false);
      setError((prev) => ({
        ...prev,
        show: true,
        message: "please select atleast one item",
        class: "error",
      }));
      return;
    }

    if ((!details.defaultAddress || !details.firstName) && isDelivery) {
      setLoading(false);
      setError((prev) => ({
        ...prev,
        show: true,
        message: "please select your customer",
        class: "error",
      }));
      return;
    }
    const username = localStorage.getItem("userName");
    const customerId = summary.customer === "DineIn" ? "CL-0008" : "CL-0008";

    let obj;

    console.log(isDelivery, typeof (isDelivery));

    if (isDelivery) {
      obj = {
        tableId: id,
        items: items,
        username: username,
        noOfGuests: summary.noOfGuests,
        customerId,
        isDelivery,
        phoneNumber: details.phoneNumber,
        details
      };
    } else {
      obj = {
        tableId: id,
        items: items,
        username: username,
        noOfGuests: summary.noOfGuests,
        customerId,
        isDelivery
      };
    }
    console.log("now sending");


    try {
      const response = await dataService.createOrder(obj);
      if (!response || typeof (response) === "undefined") {
        throw error
      }
      setError((prev) => ({
        ...prev,
        show: true,
        message: "Order Confirmed successfully.",
        class: "",
      }));
      setLoading(false);
      if (isDelivery) {
        window.location.reload()
      } else {
        navigate("/pos/main");
      }
    } catch (error) {
      setLoading(false);
      setError((prev) => ({
        ...prev,
        show: true,
        message: "Error occured while checkout.",
        class: "error",
      }));
    }
  };

  const closeRemarks = () => {
    // setModalData({ order: {}, isUpdate: false });
    modal.hide();
    setShowRemarks((prev) => ({
      ...prev,
      show: false,
      data: [],
    }));
  };
  return (
    <>
      {error.show && (
        <Alert
          message={error.message}
          ClassName={error.class}
          onClose={() =>
            setError((prev) => ({
              ...prev,
              show: false,
            }))
          }
        />
      )}

      {showReamarks.show && (
        <RemarksModal
          modal={modal}
          data={showReamarks}
          closeRemarks={() => closeRemarks}
        />
      )}

      {showCustomerModal && (
        <SearchCustomer
          showCustomerModal={showCustomerModal}
          setShowCustomerModal={setShowCustomerModal}
          changeDetails={changeDetails}
        />
      )}

      {showDetailsModal && <CustomerDetail
        selectedCustomer={details}
        setShowDetailsModal={setShowDetailsModal}
        showDetailsModal={showDetailsModal} changeDetails={changeDetails} />

      }

      <div
        className="pos-sidebar"
        id="pos-sidebar"
        style={{ position: "absolute" }}
      >
        <div className="h-100 d-flex flex-column p-0">
          <div className="pos-sidebar-header">
            <div className="back-btn">
              <button
                type="button"
                onClick={dismissMobileSidebar}
                className="btn"
              >
                <i className="fa fa-chevron-left"></i>
              </button>
            </div>
            <div className="icon">
              <i class="fa fa-plate-wheat"></i>
            </div>
            <div className="title">
              Table {id} {"  "} ({localStorage.getItem("name")})
            </div>
            <div className="order small">
              Order:{" "}
              {SalesItem
                ? SalesItem.filter(
                  (item) => item.description !== "CCO Rounding"
                ).length
                : 0}
            </div>
          </div>
          {!window.location.pathname.includes("/pos/orderDelivery/") ? (
            <div className="pos-sidebar-sub-header">
              <div class="d-flex cust_info">
                <div class="col me-1">
                  <div class="form-group">
                    <label>Customer</label>
                    <select
                      class="form-control"
                      name="customer"
                      onChange={handleSummaryChange}
                      value={summary.customer}
                    >
                      <option>DineIn</option>
                      <option>HipHop</option>
                    </select>
                  </div>
                </div>
                <div class="col ms-1">
                  <div class="form-group">
                    <label>No Of Guests</label>
                    <input
                      type="number"
                      name="noOfGuests"
                      min="0"
                      class="form-control"
                      onChange={handleSummaryChange}
                      value={summary.noOfGuests}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="pos-sidebar-sub-header">
              <div className="d-flex cust_info">
                <div className="col ms-1">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <div className="customerSearch d-flex align-items-center">
                      <AutoComplete
                        options={options}
                        onSelect={handleCustomerSelect}
                        onSearch={setSearchTerm}
                        value={searchTerm}
                        notFoundContent={fetching ? <Spin size="small" /> : null}
                        popupClassName="custom-dropdown"
                      >
                        <Input
                          placeholder="Enter customer name"
                          prefix={<SearchOutlined />}
                        />
                      </AutoComplete>
                      <Button
                        type="primary"
                        icon={<SearchOutlined />}
                        onClick={() => !fetching && setShowCustomerModal(true)}
                      />
                    </div>
                  </div>
                </div>
              </div>





              <div className="d-flex cust_info">
                <div className="col ms-1">
                  <div className="form-group">
                    <label>Address</label>
                    <div className="customerSearch">
                      <select
                        className="form-control"
                        value={details.defaultAddress} // Default address selected
                        onChange={(e) => !Loading && chaageDefaultAddress("defaultAddress", e.target.value)}

                      >
                        {details.address && [...details?.address, details.defaultAddress].map((addr, index) => (
                          <option key={index} value={addr}>
                            {addr}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          )}

          <PerfectScrollbar className="pos-sidebar-body tab-content h-100">
            <div className="tab-pane fade h-100 show active" id="newOrderTab">
              {SalesItem && SalesItem.length > 0 ? (
                SalesItem.filter(
                  (order, index) => order.description !== "CCO Rounding"
                ).map((order, index) => (
                  <div
                    className="pos-order"
                    style={{
                      backgroundColor: order.isReadOnly && "#80808029",
                      opacity: order.isReadOnly && 0.7,
                    }}
                    key={index}
                    onClick={
                      !order.isReadOnly
                        ? () => {
                          setModalData({ order, isUpdate: true, index });
                          modal.show();
                        }
                        : order.comments
                          ? () => setShowRemarks({ show: true, data: order })
                          : null
                    }
                  >
                    <div className="pos-order-product">
                      <div className="flex-1">
                        <div className="h6 mb-1">{order.description}</div>

                        <div className="d-flex">
                          <a
                            href="#/"
                            className="btn btn-secondary btn-sm"
                            onClick={
                              !order.isReadOnly
                                ? (event) => {
                                  event.stopPropagation();
                                  deductQty(event, order, index);
                                }
                                : undefined
                            }
                            style={{ cursor: order.isReadOnly && "auto" }}
                          >
                            <i className="fa fa-minus"></i>
                          </a>
                          <input
                            type="text"
                            className="form-control w-50px form-control-sm mx-2 bg-white bg-opacity-25 bg-white bg-opacity-25 text-center"
                            value={order.quantity}
                            readOnly
                            onKeyDown={(e) => {
                              e.stopPropagation();
                              handleKeyDown(e, order, index);
                            }}
                          />
                          <a
                            href="#/"
                            className="btn btn-secondary btn-sm"
                            onClick={
                              !order.isReadOnly
                                ? (event) => {
                                  event.stopPropagation();
                                  addQty(event, order, index);
                                }
                                : undefined
                            }
                            style={{ cursor: order.isReadOnly && "auto" }}
                          >
                            <i className="fa fa-plus"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="pos-order-price d-flex flex-column">
                      <div>
                        IQD{" "}
                        {isNaN(
                          !order.unitPrice
                            ? (order.unitGrossAmount * order.quantity) /
                            environment.TaxRateDivision
                            : (order.unitPrice * order.quantity) /
                            environment.TaxRateDivision
                        )
                          ? 0
                          : !order.unitPrice
                            ? parseInt(
                              (order.unitGrossAmount * order.quantity) /
                              environment.TaxRateDivision
                            ).toLocaleString()
                            : parseInt(
                              (order.unitPrice * order.quantity) /
                              environment.TaxRateDivision
                            ).toLocaleString()}
                      </div>
                      <div className="text-end mt-auto">
                        <button
                          onClick={(event) => {
                            event.stopPropagation();
                            removeOrder(event, order.uuid, order.comment);
                          }}
                          disabled={order.isReadOnly}
                          className="btn btn-default btn-sm"
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="h-100 d-flex align-items-center justify-content-center text-center p-20">
                  <div>
                    <div className="mb-3 mt-n5">
                      <i
                        className="bi bi-bag text-white text-opacity-50"
                        style={{ fontSize: "6em" }}
                      ></i>
                    </div>
                    <h5>No order found</h5>
                  </div>
                </div>
              )}
            </div>

            <div className="tab-pane fade h-100" id="orderHistoryTab">
              <div className="h-100 d-flex align-items-center justify-content-center text-center p-20">
                <div>
                  <div className="mb-3 mt-n5">
                    <svg
                      width="6em"
                      height="6em"
                      viewBox="0 0 16 16"
                      className="text-gray-300"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M14 5H2v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V5zM1 4v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4H1z"
                      />
                      <path d="M8 1.5A2.5 2.5 0 0 0 5.5 4h-1a3.5 3.5 0 1 1 7 0h-1A2.5 2.5 0 0 0 8 1.5z" />
                    </svg>
                  </div>
                  <h5>No order history found</h5>
                </div>
              </div>
            </div>
          </PerfectScrollbar>

          <div className="pos-sidebar-footer">
            <div className="d-flex align-items-center">
              <div>Subtotal</div>
              <div className="flex-1 text-end h6 mb-0">
                IQD {getSubTotalPrice().toLocaleString()}
              </div>
            </div>

            <div className="d-flex align-items-center">
              <div>Taxes (10%)</div>
              <div className="flex-1 text-end h6 mb-0">
                IQD {getTaxesPrice().toLocaleString()}
              </div>
            </div>
            {CcoRounding && (
              <div className="d-flex align-items-center">
                <div>Rounding</div>
                <div className="flex-1 text-end h6 mb-0">
                  IQD {GetCcoRounding().toLocaleString()}
                </div>
              </div>
            )}
            <hr className="opacity-1 " />
            <div className="d-flex align-items-center mb-2">
              <div>Total</div>
              <div className="flex-1 text-end h4 mb-0">
                IQD {getTotalPrice().toLocaleString()}
              </div>
            </div>
            <div className="mt-3">
              <div className="d-flex">
                <button
                  className="btn btn-theme flex-fill d-flex align-items-center justify-content-center"
                  onClick={() => checkout()}
                  disabled={Loading}
                >
                  <span>
                    {!Loading && (
                      <i className="fa fa-cash-register fa-lg my-10px d-block"></i>
                    )}

                    <span
                      className="small fw-semibold"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 9,
                        fontSize: 18,
                      }}
                    >
                      {Loading && (
                        <div className="loader-container">
                          <div class="loader3"></div>
                        </div>
                      )}
                      Submit Order
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
