import React from "react";

const CategoriesList = ({ categories, showArticles, categoryType }) => {
  return (
    <ul className="nav nav-tabs">
      {categories?.map((category, index) => (
        <li className="nav-item" key={category.uuid}>
          <a
            className={
              "nav-link" + (category.name === categoryType ? " active" : "")
            }
            onClick={(event) => showArticles(event, category, true)}
            href="#/"
          >
            {category.name}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default CategoriesList;
