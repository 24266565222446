import axios from "axios";
import { API } from "./api";

const headers = {
  "ngrok-skip-browser-warning": "true",
};

const dataService = {
  async list() {
    try {
      return await axios.get(API.list);
    } catch (error) {
      console.error("Error in dataService.list:", error);
      // throw error;
    }
  },

  async getTableArea() {
    try {
      return await axios.get(API.getTables, { headers });
    } catch (error) {
      console.error("Error in dataService.getTableArea:", error);
      // throw error;
    }
  },

  async getTables() {
    try {
      return await axios.get(API.getTables, { headers });
    } catch (error) {
      console.error("Error in dataService.getTables:", error);
      // throw error;
    }
  },

  async getCategories() {
    try {
      return await axios.get(API.getCategories, { headers });
    } catch (error) {
      console.error("Error in dataService.getCategories:", error);
      // throw error;
    }
  },

  async getArticles(categoryId) {
    try {
      return await axios.get(`${API.getArticles}/${categoryId}/${localStorage.getItem("userName")}`, { headers });
    } catch (error) {
      console.error(
        `Error in dataService.getArticles for categoryId ${categoryId}:`,
        error
      );
      // throw error;
    }
  },

  async getArticleImage(articleId) {
    try {
      return await axios.get(`${API.getArticleImage}/${articleId}`, {
        headers,
      });
    } catch (error) {
      console.error(
        `Error in dataService.getArticleImage for articleId ${articleId}:`,
        error
      );
      // throw error;
    }
  },

  async createOrder(order) {
    try {
      console.log("order is ", order);

      return await axios.post(API.createOrder, order, { headers });
    } catch (error) {
      console.error("Error in dataService.createOrder:", error);
      // throw error;
    }
  },

  async getOrder(tableId) {
    try {
      return await axios.get(`${API.getOrder}/${tableId}`, { headers });
    } catch (error) {
      console.error(
        `Error in dataService.getOrder for tableId ${tableId}:`,
        error
      );
      // throw error;
    }
  },

  async updateTableStatus(obj) {
    try {
      return await axios.post(API.updateTableStatus, obj, { headers });
    } catch (error) {
      console.error("Error in dataService.updateTableStatus:", error);
      // throw error;
    }
  },

  async fetchCustomerData(searchTerm) {
    try {
      return await axios.get(
        `${API.getAllCustomers}/${searchTerm ? searchTerm : "All"}`,
        { headers }
      );
    } catch (error) {
      console.error("Error in dataService.fetchCustomerData:", error);
    }
  },

  async addCustomer(data) {
    try {
      return await axios.put(API.addCustomer, data, { headers });
    } catch (error) {
      console.error("Error in dataService.fetchCustomerData:", error);
    }
  },

  async GetCreatedDeliveries() {
    try {
      return await axios.get(API.fetchCreatedDeliveries, { headers });
    } catch (error) {
      console.error("Error in GetCreatedDeliveries:", error);
      throw error;
    }
  },

  async GetCreatedDeliveryById(id) {
    try {
      return await axios.get(`${API.GetCreatedDeliveryById}/${id}`, {
        headers,
      });
    } catch (error) {
      console.error("Error in GetCreatedDeliveries:", error);
      throw error;
    }
  },

  async GetPendingDeliveries() {
    try {
      return await axios.get(API.GetPendingDeliveries, { headers });
    } catch (error) {
      console.error("Error in GetPendingDeliveries:", error);
      throw error;
    }
  },

  async PostDelivery(data) {
    try {
      console.log("data in PostDelivery is =>", data);

      return await axios.post(API.PostDelivery, data, { headers });
    } catch (error) {
      console.error("Error in PostDelivery:", error);
      throw error;
    }
  },
};

export default dataService;
