import axios from "axios";
import { createContext, useContext, useState, useEffect } from "react";
import { API } from "./api";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(() => {
    const userJson = localStorage.getItem("currentUser");
    return userJson ? JSON.parse(userJson) : null;
  });

  useEffect(() => {
    if (currentUser) {
      localStorage.setItem("currentUser", JSON.stringify(currentUser));
      localStorage.setItem("name", currentUser?.user?.name);
      localStorage.setItem("userName", currentUser?.user?.userName);
      localStorage.setItem("role", currentUser?.user?.role);
    } else {
      localStorage.clear();
    }
  }, [currentUser]);

  const login = async (username, password) => {
    try {
      const response = await axios.post(API.login, {
        secret: password,
        userName: username,
      });
      if (!response.data.session || !response.data.user) {
        throw new Error("Invalid Credentials");
      }
      console.log("response is ", response);
      setCurrentUser(response.data);
      return response.data;
    } catch (error) {
      console.error("Login failed", error);
      throw error;
    }
  };

  const logout = () => {
    setCurrentUser(null);
    localStorage.clear();
  };

  return (
    <AuthContext.Provider value={{ currentUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
