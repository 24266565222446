import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Input,
  List,
  Divider,
  Form,
  Row,
  Col,
  message,
  Typography, Spin
} from "antd";
import { EditOutlined, PlusOutlined, StarOutlined } from "@ant-design/icons";
import dataService from "../../_services/DataService";

const { Text } = Typography;

const CustomerDetail = ({
  setShowDetailsModal,
  showDetailsModal,
  selectedCustomer,
  changeDetails,
  fetchCustomerData,
}) => {
  const [editingAddressIndex, setEditingAddressIndex] = useState(null);
  const [addresses, setAddresses] = useState(selectedCustomer.address || []);
  const [newAddress, setNewAddress] = useState("");
  const [defaultAddress, setDefaultAddress] = useState(
    selectedCustomer.defaultAddress
  );
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setDefaultAddress(selectedCustomer.defaultAddress)
  }, [selectedCustomer])

  const handleAddAddress = () => {
    if (newAddress.trim()) {
      setAddresses([...addresses, newAddress.trim()]);
      setNewAddress("");
      message.success({ content: "Address added successfully!", duration: 1 });
    } else {
      message.error({ content: "Address cannot be empty", duration: 1 });
    }
  };



  const handleEditAddress = (index) => {
    setEditingAddressIndex(index);
  };

  const handleSaveAddress = (index, address) => {

    const updatedAddresses = addresses.map((addr, i) =>
      i === index ? address : addr
    );
    setAddresses(updatedAddresses);
    setEditingAddressIndex(null);
    message.success({ content: "Address updated successfully!", duration: 1 });
  };

  const handleCancelEdit = () => {
    setEditingAddressIndex(null);
  };

  const handleSelectDefaultAddress = (newDefault, index) => {
    setAddresses((prevAddresses) => {
      const updatedAddresses = [...prevAddresses];

      updatedAddresses[index] = defaultAddress;
      return updatedAddresses;
    });
    setDefaultAddress(newDefault);
    message.success({
      content: "Default address updated!",
      duration: 1,
    });
  };

  const handleSave = async () => {
    setLoading(true)
    const updatedCustomer = {
      ...selectedCustomer,
      address: addresses,
      defaultAddress,
    };



    try {
      const response = await dataService.addCustomer(updatedCustomer);
      if (fetchCustomerData) fetchCustomerData()
      if (changeDetails) changeDetails(updatedCustomer)
      setLoading(false)
      message.success({ content: "Customer details saved!", duration: 2 });
      setShowDetailsModal(false);
    } catch (error) {
      setLoading(false)
      message.error({ content: "Failed to save customer details!", duration: 2 });
      console.error("Error saving customer details:", error);
    }



  };

  return (
    <Modal
      title={
        <Row justify="space-between" align="middle">
          <Col>
            <strong>Customer Details</strong>
          </Col>
          <Col>
            <Button type="primary" onClick={handleSave} style={{ marginRight: 8 }} disabled={loading}>
              Save
            </Button>
            <Button onClick={() => setShowDetailsModal(false)}>Cancel</Button>
          </Col>
        </Row>
      }
      style={{
        top: 20,
      }}
      visible={showDetailsModal}
      onCancel={() => setShowDetailsModal(false)}
      footer={null}
      closable={false}
      width={900}
    >
      <Spin spinning={loading}>
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label={<strong>First Name</strong>}
              style={{ marginBottom: 8 }}
            >
              {selectedCustomer.firstName}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label={<strong>Last Name</strong>}
              style={{ marginBottom: 8 }}
            >
              {selectedCustomer.lastName}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label={<strong>Phone Number</strong>}
              style={{ marginBottom: 8 }}
            >
              {selectedCustomer.phoneNumber}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label={<strong>Remarks</strong>}
              style={{ marginBottom: 8 }}
            >
              {selectedCustomer.udfStringXL1}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label={<strong>Default Address</strong>}
              style={{ marginBottom: 8 }}
            >
              {defaultAddress}
            </Form.Item>
          </Col>
        </Row>

        <p>
          <strong>Addresses:</strong>
        </p>
        <Row gutter={8} align="middle">
          <Col flex="auto">
            <Input
              placeholder="Add new address"
              value={newAddress}
              onChange={(e) => setNewAddress(e.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleAddAddress()
                }
              }}
            />
          </Col>
          <Col>
            <Button
              type="dashed"
              icon={<PlusOutlined />}
              onClick={handleAddAddress}
            >
              Add Address
            </Button>
          </Col>
        </Row>

        <Divider />

        <List
          bordered
          dataSource={addresses}
          renderItem={(item, index) => (
            <List.Item
              actions={[
                <Button
                  icon={<StarOutlined />}
                  onClick={() => handleSelectDefaultAddress(item, index)}
                  disabled={defaultAddress === item}
                  title="Set as Default Address"
                >
                  {defaultAddress === item ? "Default" : "Set as Default"}
                </Button>,
                <Button
                  icon={<EditOutlined />}
                  onClick={() => handleEditAddress(index)}
                >
                  Edit
                </Button>,
              ]}
            >
              {editingAddressIndex === index ? (
                <Form
                  layout="inline"
                  onFinish={(values) => handleSaveAddress(index, values.address)}
                >
                  <Form.Item
                    name="address"
                    initialValue={item}
                    rules={[{ required: true, message: "Address is required" }]}
                  >
                    <Input onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        handleSaveAddress(index, event.target.value)
                      }
                    }} />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                    <Button
                      onClick={handleCancelEdit}
                      style={{ marginLeft: "8px" }}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <span>{item}</span>
              )}
            </List.Item>
          )}
        />
      </Spin>
    </Modal>
  );
};

export default CustomerDetail;
