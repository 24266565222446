// src/api/index.js
import { environment } from "./environment";

export const API = {
  login: environment.TableBaseURL + "/api/TableManagement/SignIn",
  list: "",
  getCategories:
    environment.TableBaseURL + "/api/tablemanagement/getcategories",
  getArticles: environment.TableBaseURL + "/api/tablemanagement/getarticles",
  getArticleImage:
    environment.TableBaseURL + "/api/tablemanagement/GetArticleImage",
  getAreas: environment.TableBaseURL + "/api/tablemanagement/GetTablesArea",
  getTables: environment.TableBaseURL + "/api/tablemanagement/GetTables",
  createOrder: environment.TableBaseURL + "/api/TableManagement/CreateOrder",
  getOrder: environment.TableBaseURL + "/api/TableManagement/GetOrder",
  updateTableStatus:
    environment.TableBaseURL + "/api/TableManagement/UpdateTableStatus",
  getAllCustomers:
    environment.TableBaseURL + "/api/TableManagement/GetAllCustomers",
  addCustomer: environment.TableBaseURL + "/api/TableManagement/addCustomer",

  getDelevries: environment.TableBaseURL + "",

  fetchCreatedDeliveries:
    environment.TableBaseURL + "/api/Delivery/FetchCreatedDeliveries",

  GetCreatedDeliveryById:
    environment.TableBaseURL + "/api/Delivery/FetchCreatedDeliveriesById",

  GetPendingDeliveries:
    environment.TableBaseURL + "/api/Delivery/FetchPendingDeliveries",

  PostDelivery: environment.TableBaseURL + "/api/Delivery/PostDelivery",
};
