import React, { useEffect, useState } from "react";
import Alert from "../../components/alert/alert";
import todayLogo from "../../assets/images/today-logo.png";
import { useAuth } from "../../_services/AuthenticationService";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "antd";
import "./login.scss";

const Login = () => {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [deliverySettelment, setDeliverySettelment] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const userName = localStorage.getItem("userName");
    const role = localStorage.getItem("role");
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const settelement = localStorage.getItem("deliverySettelment");
    console.log(
      "username>>>>>>>>>>>>>>>>>>>>>",
      userName,
      currentUser,
      currentUser?.user?.role,
      settelement
    );
    if (userName && currentUser?.user?.role === "Delivery" && settelement) {
      navigate("/pos/deliveries");
      return;
    }
    if (userName && currentUser?.user?.role === "Delivery") {
      navigate("/pos/orderDelivery/" + currentUser.user.userName);
      return;
    }
    if (userName) navigate("/pos/main");
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (!formData.username || !formData.password) {
      return;
    }

    setLoading(true);
    setErrorMessage("");

    try {
      const response = await login(formData.username, formData.password);

      console.log(response.user.role);
      if (response.user.role === "Delivery" && deliverySettelment) {
        localStorage.setItem("delivery", false);
        setLoading(false);
        navigate("/pos/deliveries");
        return;
      }
      if (response.user.role === "Delivery") {
        localStorage.setItem("delivery", true);
        navigate("/pos/orderDelivery/" + formData.username);

        setLoading(false);
        return;
      }
      localStorage.setItem("delivery", false);
      navigate("/pos/main");
    } catch (error) {
      setLoading(false);
      console.log("error occured during login", error);
      setErrorMessage(error.message || "Error Occured");
    }
  };

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setDeliverySettelment(e.target.checked);
    localStorage.setItem("deliverySettelment", e.target.checked);
  };

  return (
    <>
      {errorMessage && (
        <Alert
          message={errorMessage}
          ClassName="error"
          onClose={() => setErrorMessage("")}
        />
      )}
      <div className="demo-container">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-xl-4 col-md-5">
              <div className="design_block">
                <img
                  className="logo"
                  src={todayLogo}
                  alt="Today Restaurant Logo"
                />
              </div>
            </div>

            <div className="col-xl-7 col-9 col-md-6">
              <div className="form_cont p-5">
                <div>
                  <h2 className="mb-2">Welcome to Today Restaurant</h2>
                  <p className="lead">Sign In to manage your tables</p>

                  <form onSubmit={handleSubmit}>
                    <div className="form-group mb-4">
                      <label htmlFor="username">Username</label>
                      <input
                        type="text"
                        name="username"
                        className={`form-control ${
                          submitted && !formData.username ? "is-invalid" : ""
                        }`}
                        value={formData.username}
                        onChange={handleChange}
                      />
                      {submitted && !formData.username && (
                        <div className="invalid-feedback">
                          Username is required
                        </div>
                      )}
                    </div>
                    <div className="form-group mb-2">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        name="password"
                        className={`form-control ${
                          submitted && !formData.password ? "is-invalid" : ""
                        }`}
                        value={formData.password}
                        onChange={handleChange}
                      />
                      {submitted && !formData.password && (
                        <div className="invalid-feedback">
                          Password is required
                        </div>
                      )}
                    </div>
                    <Checkbox onChange={onChange}>Delivery Settlement</Checkbox>
                    <button
                      type="submit"
                      disabled={loading}
                      className="btn btn-success btn-lg shadow-lg"
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Login
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
