import React, { useState } from "react";
import { Modal } from "../../components/modal/modal";
import Alert from "../../components/alert/alert";
import { useNavigate } from "react-router-dom";

export const SummaryModal = ({
  setSummaryModal,
  summaryModal,
  summary,
  setSummary,
}) => {
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  function handleModalClose() {
    setSummaryModal(false);
    navigate("/pos/main");
  }

  function handleModalOk() {
    if (summary.noOfGuests <= 0) {
      setError(true);
      return;
    }
    setSummaryModal(false);
  }

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      setSummary((prev) => ({
        ...prev,
        noOfGuests: prev.noOfGuests + 1,
      }));
    } else if (event.key === "ArrowDown") {
      setSummary((prev) => ({
        ...prev,
        noOfGuests: Math.max(prev.noOfGuests - 1, 1),
      }));
    }
  };

  return (
    <>
      {error && (
        <Alert
          message="Please select positive no of guests."
          ClassName="error"
          onClose={() => setError(false)}
        />
      )}
      <Modal>
        <div className="modal-header">
          <h5 className="modal-title">
            Please select Customer and no of Guests to proceed.
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={handleModalClose}
          ></button>
        </div>
        <div className="modal-body">
          <div class="d-flex cust_info" style={{ gap: 32 }}>
            <div class="col me-1">
              <div class="form-group">
                <label>Customer</label>
                <select
                  style={{ marginBottom: 20 }}
                  class="form-control"
                  name="customer"
                  onChange={(event) => {
                    setSummary((prev) => ({
                      ...prev,
                      customer: event.target.value,
                    }));
                  }}
                  value={summary.customer}
                >
                  <option>DineIn</option>
                  <option>HipHop</option>
                </select>
              </div>
            </div>
            <div class="col ms-1">
              <div class="form-group">
                <label>No Of Guests</label>
                <div className="d-flex mb-3">
                  <button
                    className="btn btn-secondary"
                    onClick={(event) =>
                      setSummary((prev) => ({
                        ...prev,
                        noOfGuests: Math.max(prev.noOfGuests - 1, 1),
                      }))
                    }
                  >
                    <i className="fa fa-minus"></i>
                  </button>
                  <input
                    type="text"
                    readOnly
                    className="form-control w-50px fw-bold mx-2 text-center"
                    value={summary.noOfGuests}
                    onKeyDown={handleKeyDown}
                  />
                  <button
                    className="btn btn-secondary"
                    onClick={(event) =>
                      setSummary((prev) => ({
                        ...prev,
                        noOfGuests: prev.noOfGuests + 1,
                      }))
                    }
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-default"
            onClick={handleModalClose}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-outline-theme"
            onClick={handleModalOk}
          >
            Confirm
          </button>
        </div>
      </Modal>
    </>
  );
};
