import React from "react";
import { Modal } from "../../components/modal/modal";

export const RemarksModal = ({ data, closeRemarks }) => {
  console.log("data is ", data);
  return (
    <Modal>
      <div className="previous-comment-container">
        <div className="p-0">
          <button
            onClick={closeRemarks()}
            className="btn-close position-absolute top-0 end-0 m-4"
          >
            &nbsp;
          </button>
          <h2>Comments:</h2>
          <textarea
            class="form-control"
            rows="5"
            cols="3"
            value={data.data.comments}
          ></textarea>
        </div>
      </div>
    </Modal>
  );
};
