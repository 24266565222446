import React, { useEffect } from "react";
import "./alert.scss";

export const Alert = ({ message, onClose, ClassName }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, 2000);
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className={`custom-alert ${ClassName}`}>
      <span>{message}</span>
    </div>
  );
};

export default Alert;
