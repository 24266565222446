import React, { useEffect, useRef, useState } from "react";
import logo from "../../assets/images/today-logo.png";
import { Link, useParams } from "react-router-dom";
import "./receipt.css";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Loading from "../../components/loader/Loading";
import dataService from "../../_services/DataService";
import { DateTime } from "../../utility/date-time";

const Receipt = () => {
  const [loading, setLoading] = useState(false);
  const [deliveryDetails, setDeliveryDetails] = useState({});

  const { id } = useParams();
  console.log("delivery details are ", deliveryDetails);

  const fetchDelivery = async (id) => {
    try {
      setLoading(true);
      const response = await dataService.GetCreatedDeliveryById(id);
      console.log(response, "from get delivery by id");

      setDeliveryDetails(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (id) {
      fetchDelivery(id);
    }
  }, [id]);
  const navigate = useNavigate();
  const receiptRef = useRef();

  const handlePrint = () => {
    const originalContents = document.body.innerHTML;
    const printContents = receiptRef.current.innerHTML;

    document.body.innerHTML = printContents;

    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };

  return (
    <div className="pos pos-with-header">
      <div className="pos-container">
        <div className="pos-header">
          <div className="logo">
            <Link to="/pos/orderplacement">
              <img src={logo} className="logo-img" alt="logo" />
            </Link>
          </div>
          <div className="nav">
            <div className="nav-item">
              <Link
                to="/"
                onClick={() => localStorage.clear()}
                className="nav-link"
              >
                <i className="fa fa-sign-out-alt"></i> Logout
              </Link>
            </div>
          </div>
        </div>
        <Loading isLoading={loading}>
          <div
            className="pos-content pos-tables"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "24%",
              }}
            >
              <div className="receipt" ref={receiptRef}>
                <div className="business_logo">
                  {" "}
                  <img src={logo} className="logo-img" alt="logo" />
                </div>
                <h2 className="business_name">Delivery Collection</h2>
                <div className="receipt-details">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h6>ID:</h6>{" "}
                    <span>{deliveryDetails?.deliveryID ?? ""}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h6>Invoice Date:</h6>{" "}
                    <span>
                      {DateTime.parse(
                        deliveryDetails?.invoiceDate
                      ).toString() ?? ""}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h6>Delivery Person ID:</h6>{" "}
                    <span>{deliveryDetails?.deliveryPersonID ?? ""}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h6>Name:</h6>{" "}
                    <span>{deliveryDetails?.deliveryPersonName ?? ""}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h6>Total Amount:</h6>{" "}
                    <span>
                      {deliveryDetails?.totalAmount?.toLocaleString() ?? ""}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h6>Received:</h6>{" "}
                    <span>
                      {(parseFloat(deliveryDetails.totalAmount)-parseFloat(deliveryDetails.remainingAmount)).toLocaleString()}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h6>Difference:</h6>{" "}
                    <span>
                      {deliveryDetails?.remainingAmount?.toLocaleString() ?? ""}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="no_print"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  marginTop: 9,
                }}
              >
                <Button
                  className="noPrintBtn"
                  style={{ width: "45%" }}
                  onClick={() => navigate("/pos/deliveries")}
                  icon={<ArrowLeftOutlined />}
                >
                  Back
                </Button>
                <Button
                  className="noPrintBtn"
                  type="primary"
                  style={{ width: "45%" }}
                  onClick={handlePrint}
                >
                  Print
                </Button>
              </div>
            </div>
          </div>
        </Loading>
      </div>
    </div>
  );
};

export default Receipt;
