import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Button, Input, Space, Spin, Empty, message } from "antd";
import logo from "../../assets/images/today-logo.png";
import dataService from "../../_services/DataService";
import { useNavigate } from "react-router-dom";
import { DateTime } from "../../utility/date-time";
import "./Delivery.scss";

const Deliveries = () => {
  // Sample data for the table
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await dataService.GetCreatedDeliveries();
      console.log("response form fetchCreatedDeliveries", response);

      setData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  // Filter data based on search text
  const filteredData = data.filter(
    (item) =>
      item?.deliveryID?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.deliveryPersonName?.toLowerCase().includes(searchText.toLowerCase())
  );

  const handlePendingDeliveries = () => {
    console.log("Pending deliveries function triggered");
    navigate("/pos/pendingDeliveries");
  };

  const handlePostInvoice = async (deliveryID) => {
    console.log("Posting invoice for Delivery ID:", deliveryID);
    if (!deliveryID) return;
    navigate(`/pos/delivery/recepit/${deliveryID}`);
  };

  const columns = [
    {
      title: "Delivery ID",
      dataIndex: "deliveryID",
      key: "deliveryID",
      render: (text, record) => `${record.deliveryID}`,
    },
    {
      title: "Delivery Settlement Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      render: (text) => {
        try {
          const formattedDate = DateTime.parse(text).toString();
          return formattedDate ? formattedDate : "Invalid Date";
        } catch (error) {
          return "Invalid Date";
        }
      },
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text) => {
        try {
          
          return text.toLocaleString();
        } catch (error) {
          return "Invalid Date";
        }
      }
    },
    {
      title: "Delivery Person ID",
      dataIndex: "deliveryPersonID",
      key: "deliveryPersonID",
    },
    {
      title: "Delivery Person Name",
      dataIndex: "deliveryPersonName",
      key: "deliveryPersonName",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          type="primary"
          onClick={() => handlePostInvoice(record.deliveryID)}
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="pos pos-with-header">
        <div className="pos-container">
          <div className="pos-header">
            <div className="logo">
              <Link to="/pos/orderplacement" href="pos_counter_checkout.html">
                <div className="logo-text">
                  <img src={logo} className="logo-img" alt="logo" />
                </div>
              </Link>
            </div>

            <div className="nav">
              <div className="nav-item">
                <Link
                  to="/"
                  onClick={() => localStorage.clear()}
                  className="nav-link"
                >
                  <i className="fa fa-sign-out-alt"></i> Logout
                </Link>
              </div>
            </div>
          </div>

          <div className="pos-content pos-delivery">
            <Space
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                marginBottom: 12,
              }}
            >
              <Input
                placeholder="Search Deliveries"
                value={searchText}
                onChange={handleSearchChange}
                style={{ width: 300 }}
              />
              <Button onClick={handlePendingDeliveries} type="dashed">
                Pending Deliveries
              </Button>
            </Space>

            <Table
              columns={columns}
              dataSource={filteredData ?? []}
              rowKey="deliveryID"
              loading={loading}
              pagination={false}
              size="medium"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Deliveries;
