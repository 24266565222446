import React from "react";
import App from "./../app.jsx";
import OrderProcessor from "../pages/order-processor/order-processor.js";
import OrderPlacement from "../pages/orderPlacement/orderPlacment.js";
import Login from "../pages/login/login.js";
import Deliveries from "../pages/deliveries/Deliveries.jsx";
import PendingDeliveries from "../pages/deliveries/PendingDeliveries.jsx";
import Receipt from "../pages/deliveries/Receipt.jsx";

const getRoutes = () => {
  const isDelivery = localStorage.getItem("delivery") === "true";

  const routes = [
    {
      path: "*",
      element: <App />,
      children: [
        { path: "", element: <Login /> },
        {
          path: "pos/*",
          children: [
            { path: "orderplacement/:id", element: <OrderPlacement /> },
            !isDelivery && { path: "main", element: <OrderProcessor /> },
            { path: "orderDelivery/:id", element: <OrderPlacement /> },
          ].filter(Boolean),
        },
        {
          path: "pos/deliveries",
          element: <Deliveries />,
        },
        {
          path: "pos/pendingDeliveries",
          element: <PendingDeliveries />,
        },
        {
          path: "pos/delivery/recepit/:id",
          element: <Receipt />,
        },
      ],
    },
  ];

  return routes;
};

const AppRoute = getRoutes();

export default AppRoute;
