import React, { useEffect, useState } from "react";
import {
  Modal,
  Table,
  Input,
  Button,
  Row,
  Col,
  Empty,
  Spin,
  message, Space
} from "antd";
import {
  SearchOutlined,
  EyeOutlined,
  UserAddOutlined, CheckOutlined
} from "@ant-design/icons";
import CustomerDetail from "./CustomerDetail";
import AddCustomerModal from "./AddCustomer";
import dataService from "../../_services/DataService";

const SearchCustomer = ({ showCustomerModal, setShowCustomerModal, ChangeCustomerDetails, changeDetails }) => {
  const [customerData, setCustomerData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await dataService.fetchCustomerData(searchTerm);
      setFilteredData(response.data);
    } catch (error) {
      message.error("Failed to fetch customer data");
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomerData = async () => {
    setLoading(true);
    try {
      const response = await dataService.fetchCustomerData();

      setCustomerData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      message.error("Failed to fetch customer data");
    } finally {
      setLoading(false);
    }
  };


  const handleRowSelection = (selectedRows) => {
    const selected = selectedRows;
    changeDetails(selected)
    setSelectedCustomer(selected);
    const filteredAddresses = selected.address.filter(
      (addr) => addr !== selected.defaultAddress
    );

    setShowCustomerModal(false)
  };

  const handleView = (record) => {
    setSelectedCustomer(record)
    setShowDetailsModal(true)
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Default Address",
      dataIndex: "defaultAddress",
      key: "defaultAddress",
    },
    {
      title: "Remarks",
      dataIndex: "udfStringXL1",
      key: "remarks",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button
            type="dashed"
            icon={<EyeOutlined />}
            onClick={() => handleView(record)}
          >
            View
          </Button>
          <Button
            type="primary"
            icon={<CheckOutlined />}
            onClick={() => handleRowSelection(record)}
          >
            Select
          </Button>
        </Space>
      ),
    },
  ];


  const changeSearch = (value) => {
    fetchCustomerData()
    setSearchTerm(value)
  }


  return (
    <>
      <Modal
        title="Search Customer"
        style={{
          top: 35,
        }}
        visible={showCustomerModal}
        onCancel={() => setShowCustomerModal(false)}
        footer={null}
        width={1300}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} lg={16} xl={18}>
            <Input
              placeholder="Enter customer name or phone number"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);

              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleSearch()
                }
              }}
            />
          </Col>
          <Col xs={12} sm={6} lg={4} xl={3}>
            <Button
              type="default"
              icon={<SearchOutlined />}
              onClick={handleSearch}
              block
            >
              Search
            </Button>
          </Col>
          <Col xs={12} sm={6} lg={4} xl={3}>
            <Button
              type="primary"
              icon={<UserAddOutlined />}
              onClick={() => setShowAddCustomerModal(true)}
              block
            >
              Add
            </Button>

          </Col>
        </Row>

        <Spin spinning={loading} style={{ marginTop: "20px" }}>
          {filteredData.length > 0 ? (
            <Table
              columns={columns}
              dataSource={filteredData}
              rowKey="id"
              // rowSelection={{
              //   type: "radio",
              //   onChange: handleRowSelection,
              // }}
              scroll={{ y: 350 }}
              style={{ marginTop: "20px" }}
            />
          ) : (
            (!loading || filteredData.length <= 0) && (
              <Empty
                description="No Customer Data"
                style={{ marginTop: "20px" }}
              />
            )
          )}
        </Spin>
      </Modal>

      {selectedCustomer && (
        <CustomerDetail
          selectedCustomer={selectedCustomer}
          setShowDetailsModal={setShowDetailsModal}
          showDetailsModal={showDetailsModal}
          fetchCustomerData={fetchCustomerData}
        />
      )}
      {showAddCustomerModal && (
        <AddCustomerModal
          visible={showAddCustomerModal}
          onClose={() => setShowAddCustomerModal(false)}
          setSearchTerm={changeSearch}

        />
      )}
    </>
  );
};

export default SearchCustomer;
