import React, { useState, useEffect } from "react";

export const TableTimer = ({ table }) => {
  const [timePassed, setTimePassed] = useState();

  useEffect(() => {
    setTimePassed(table.time ? calculateTimePassed(table.time) : 0);
  }, []);

  useEffect(() => {
    if (table.status.toUpperCase() === "ORDERED" && table.time) {
      const interval = setInterval(() => {
        setTimePassed((prevTime) => prevTime + 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [table.status, table.time]);

  return (
    <div className="w-60px">
      {table.status.toUpperCase() === "ORDERED" && table.time
        ? formatTimer(calculateTimePassed(table.time))
        : "-"}
    </div>
  );
};

const padZero = (value) => {
  return value < 10 ? `0${value}` : `${value}`;
};

const formatTimer = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${padZero(hours)}:${padZero(minutes)}:${padZero(remainingSeconds)}`;
};

const calculateTimePassed = (orderTimeInSecondsOfDay) => {
  const now = Math.floor(Date.now() / 1000);
  const timePassed = now - orderTimeInSecondsOfDay;
  return timePassed;
};
