import moment from "moment";

export class DateTime {
  constructor(date) {
    this._date = date;
  }

  static parse(dateString) {
    const date = moment(dateString);
    return new DateTime(date);
  }

  static now() {
    const date = moment();
    return new DateTime(date);
  }

  toString() {
    return this._date.format("YYYY-MM-DD hh:mm");
  }

  toDateString() {
    return this._date.format("DD MMMM YYYY");
  }
}
